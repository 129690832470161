import { useModalState } from "@clipboard-health/ui-react";
import { useGetChatChannels } from "@src/appV2/Facilities/Chat/api/useGetChatChannels";
import { type ShiftInvite } from "@src/appV2/Shifts/ShiftInvites/types";
import constate from "constate";
import { useState } from "react";

import { type Shift } from "../../Shift/types";

function useShiftInviteItem() {
  const [conflictingShift, setConflictingShift] = useState<Shift>();
  const [conflictingInvites, setConflictingInvites] = useState<ShiftInvite[]>([]);
  const conflictingShiftDialogState = useModalState();
  const conflictingInviteDialogState = useModalState();
  const overworkingShiftDialogState = useModalState();
  const missingFacilityRequiredDocumentsDialogState = useModalState();
  const missingCoreRequiredDocumentsDialogState = useModalState();
  const [missingDocuments, setMissingDocuments] = useState<string[]>([]);
  const weeklyHoursLimitExceededDialogState = useModalState();
  const [weeklyHoursLimit, setWeeklyHoursLimit] = useState<number>(0);
  const { data: chatChannelsData } = useGetChatChannels();
  const [shouldDeclineConflictingInvites, setShouldDeclineConflictingInvites] = useState(false);
  const [skipFacilityRequiredDocumentCheck, setSkipFacilityRequiredDocumentCheck] = useState(false);
  const [isOverworkingShiftsAllowed, setIsOverworkingShiftsAllowed] = useState(false);

  const resetWarnings = () => {
    setShouldDeclineConflictingInvites(false);
    setSkipFacilityRequiredDocumentCheck(false);
    setIsOverworkingShiftsAllowed(false);
  };

  return {
    conflictingShift,
    setConflictingShift,
    conflictingInvites,
    setConflictingInvites,
    shouldDeclineConflictingInvites,
    setShouldDeclineConflictingInvites,
    skipFacilityRequiredDocumentCheck,
    setSkipFacilityRequiredDocumentCheck,
    isOverworkingShiftsAllowed,
    setIsOverworkingShiftsAllowed,
    resetWarnings,
    conflictingShiftDialogState,
    conflictingInviteDialogState,
    overworkingShiftDialogState,
    missingFacilityRequiredDocumentsDialogState,
    missingCoreRequiredDocumentsDialogState,
    missingDocuments,
    setMissingDocuments,
    weeklyHoursLimitExceededDialogState,
    weeklyHoursLimit,
    setWeeklyHoursLimit,
    chatChannelsData,
  };
}

export const [ShiftInviteItemProvider, useShiftInviteItemContext] = constate(useShiftInviteItem);
